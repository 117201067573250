<mat-icon mat-button class="close" [mat-dialog-close]="true"
  ><span class="svg-icon svg-icon-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.5"
        x="6"
        y="17.3137"
        width="16"
        height="2"
        rx="1"
        transform="rotate(-45 6 17.3137)"
        fill="black"
      ></rect>
      <rect
        x="7.41422"
        y="6"
        width="16"
        height="2"
        rx="1"
        transform="rotate(45 7.41422 6)"
        fill="black"
      ></rect>
    </svg> </span
></mat-icon>

<div class="d-flex justify-content-between">
  <h2 mat-dialog-title>Activity Properties: {{ description }}</h2>
</div>

<mat-dialog-content [formGroup]="form">
  <input hidden matInput formControlName="workflowid" />
  <input hidden matInput formControlName="formid" />
  <input hidden matInput formControlName="nodeid" />
  <div class="w-100 mb-8 mt-5">
    <div class="notice d-flex bg-secondary border-primary border p-2">
      <button
        (click)="close()"
        class="btn btn-icon btn-sm btn-default icon-chevron-left navbar-btn mt-1"
        role="link"
        data-original-title="Back"
        aria-expanded="true"
      >
        <span class="sr-only">Back</span>
      </button>

      <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
        <div class="mb-md-0 fw-bold">
          <h4 class="text-gray-900 fw-bolder">
            Workflow Activity -
            {{ description }} for [ {{ formname }} ]
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-10">
    <div class="row">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5 required">Name</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Script Name</mat-label>
            <input matInput formControlName="workflowname" />
          </mat-form-field>
        </div>
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-xl-6">
        <div class="row">
          <div class="col-xl-4 text-right">
            <div class="fs-6 fw-bold mt-5 mb-3">Stage</div>
          </div>
          <div class="col-xl-8 fv-row fv-plugins-icon-container">
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="runfield">
                <mat-label>Script Stage</mat-label>
                <input matInput formControlName="workflowstage" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="row">
          <div class="col-xl-3 text-right">
            <div class="fs-6 fw-bold mt-5 mb-3">Place</div>
          </div>
          <div class="col-xl-8 fv-row fv-plugins-icon-container">
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="runfield2">
                <mat-label>Script Place</mat-label>
                <mat-select
                  formControlName="scriptplace"
                  (selectionChange)="getScriptPlace($event)"
                >
                  <mat-option value="head">Head</mat-option>
                  <mat-option value="body">Body</mat-option>
                  <mat-option value="onload">onLoad</mat-option>
                  <mat-option value="onsave">onSave</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5">Script Type</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12 mt-5">
          <mat-radio-group (change)="toggleScript($event)" start="align">
            <mat-radio-button
              *ngFor="let option of scripttypeOptions"
              [checked]="option.checked"
              value="{{ option.value }}"
              >{{ option.label }}
            </mat-radio-button>
          </mat-radio-group>
          <select
            class="btn_psecondary slc-integration"
            (change)="hubChange($event)"
            [ngClass]="slchub ? 'int_show' : 'hide'"
          >
            <option value="0">Select</option>
            <option value="servicenow" [selected]="isservicenow">
              Service Now
            </option>
            <option value="salesforce" [selected]="issalesforce">
              Salesforce
            </option>
          </select>
        </div>
        <div class="col-md-3 mt-3"></div>
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
    </div>
  </div>
  <ng-container>
    <div class="modal-body scroll-y mx-5 pt-0 pb-15">
      <div class="col-md-12 card pt-0 mb-6 mb-xl-9">
        <div class="d-flex flex-column mb-8 fv-row mt-8">
          <div class="row">
            <div class="col-xs-12">
              <table style="width: 100%" class="" role="presentation">
                <tbody>
                  <tr
                    class="annotation-row"
                    data-annotation-type="Info Box Blue"
                    style="display: table-row"
                  >
                    <td
                      colspan="99"
                      class="annotation"
                      data-annotation-type="Info Box Blue"
                    >
                      <div
                        class="annotation-wrapper"
                        style="padding: 2px 0px 4px 4px"
                        role="note"
                      >
                        The Run Script activity runs the specified script in the
                        scope of the workflow version.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div [ngClass]="isgeneral ? 'show' : 'hide'">
          <div class="row">
            <div class="col-xl-2 text-right">
              <div class="fs-6 fw-bold mt-5 mb-3">Trigger Point</div>
            </div>
            <div class="col-xl-9 fv-row fv-plugins-icon-container">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <select
                    class="form-select btn_psecondary paramcolumns"
                    tabindex="-1"
                    (change)="paramChange($event)"
                  >
                    <option value="0" selected="Selected">Select</option>
                    <option
                      *ngFor="let control of formControls"
                      [value]="control.controlType"
                      [attr.data-id]="control.id"
                      [selected]="paramcolumns == control.fieldName"
                    >
                      {{ control.fieldName }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mt-2" *ngIf="isgeneralSlcTrigger">
                  <div class="row">
                    <div class="col-md-6 text-right">
                      <div class="fs-6 fw-bold mt-3 mb-3">Dropdown Options</div>
                    </div>
                    <div
                      class="col-md-6 fv-row fv-plugins-icon-container pl-0 pr-0"
                    >
                      <select
                        class="form-select btn_psecondary bindtrigger"
                        tabindex="-1"
                        (change)="bindTriggerChange($event)"
                      >
                        <option
                          *ngFor="let option of generalSlcTrigger"
                          [value]="option.id"
                          [attr.data-id]="option.id"
                          [selected]="triggerbindby == option.id"
                        >
                          {{ option.value }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2 mt-2">
            <div class="col-xl-2">
              <div class="fs-6 fw-bold mt-3 mb-3">Script</div>
            </div>
            <div class="col-xl-10 fv-row fv-plugins-icon-container">
              <textarea
                id="qf_appr_run_script"
                name="qf_appr_run_script"
                class="tox-target"
                formControlName="runscript"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row mb-2 mt-2" [ngClass]="httprequest ? 'show' : 'hide'">
          <div class="col-md-12">
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Method</div>
              </div>
              <div class="col-xl-6 mt-5 fv-row fv-plugins-icon-container mb-5">
                <mat-radio-group
                  start="align"
                  (change)="toggleMethod($event)"
                  class="url"
                >
                  <mat-radio-button
                    *ngFor="let option of methodtypeOptions"
                    [checked]="option.checked"
                    value="{{ option.value }}"
                    >{{ option.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-xl-4 fv-row text-right" *ngIf="!ispublished">
                <a
                  href="javascript:void(0);"
                  mat-raised-button
                  color="primary"
                  (click)="validateapi()"
                  >Validate</a
                >
              </div>
            </div>

            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Trigger Point</div>
              </div>
              <div class="col-xl-9 fv-row fv-plugins-icon-container">
                <div class="row">
                  <div class="col-md-4 mt-2">
                    <select
                      class="form-select btn_psecondary paramcolumns"
                      tabindex="-1"
                      (change)="paramChange($event)"
                    >
                      <option value="0" selected="Selected">Select</option>
                      <option
                        *ngFor="let control of formControls"
                        [value]="control.controlType"
                        [attr.data-id]="control.id"
                        [selected]="paramcolumns == control.fieldName"
                      >
                        {{ control.fieldName }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3 mt-2" *ngIf="triggerCtrl == 'dropdown'">
                    <div class="row">
                      <div class="col-md-6 text-right">
                        <div class="fs-6 fw-bold mt-3 mb-3">Bind By</div>
                      </div>
                      <div
                        class="col-md-6 fv-row fv-plugins-icon-container pl-0 pr-0"
                      >
                        <select
                          class="form-select btn_psecondary bindtrigger"
                          tabindex="-1"
                          (change)="bindTriggerChange($event)"
                        >
                          <option value="id" [selected]="triggerbindby == 'id'">
                            Id
                          </option>
                          <option
                            value="value"
                            [selected]="triggerbindby == 'value'"
                          >
                            Value
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="row">
                      <div class="col-xl-6 text-right">
                        <div class="fs-6 fw-bold mt-5 mb-3">API Data Point</div>
                      </div>
                      <div class="col-xl-6 fv-row fv-plugins-icon-container">
                        <mat-form-field
                          appearance="outline"
                          [class]="
                            triggerCtrl == 'dropdown' ? 'col11' : 'col23'
                          "
                        >
                          <mat-label>API Column</mat-label>
                          <input matInput formControlName="apicolindex" />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Url</div>
              </div>
              <div class="col-xl-8 fv-row fv-plugins-icon-container">
                <mat-form-field appearance="outline" class="url">
                  <mat-label>Url</mat-label>
                  <input matInput formControlName="apiurl" />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Header</div>
              </div>
              <div class="col-xl-8 fv-row fv-plugins-icon-container">
                <textarea
                  formControlName="apiheaders"
                  rows="5"
                  cols="40"
                  class="form-control apiheader"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Params</div>
              </div>
              <div class="col-xl-8 fv-row fv-plugins-icon-container">
                <textarea
                  formControlName="apiparams"
                  rows="5"
                  cols="40"
                  class="form-control apiheader"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Columns</div>
              </div>
              <div class="col-xl-7 fv-row fv-plugins-icon-container">
                <mat-form-field appearance="outline" class="apicolumn">
                  <mat-label>Columns</mat-label>
                  <input matInput formControlName="apicolumns" />
                </mat-form-field>
              </div>
              <div class="col-xl-2">
                <div class="fs-6 fw-bold ml-1 mt-1 mb-3">
                  <button
                    type="button"
                    class="btn btn-outline-info waves-effect px-3"
                    (click)="colMaping()"
                  >
                    <span
                      ><img
                        src="../../../assets/media/images/network.png"
                        class="icon-mapping"
                        alt="Map Columns"
                        title="Map Columns"
                    /></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3"></div>
              </div>
              <div class="col-xl-8 fv-row fv-plugins-icon-container">
                <div
                  class="row mb-1"
                  *ngFor="let columns of arrcolumns; let i = index"
                >
                  <div
                    [ngClass]="{
                      'col-md-4': onSave == true,
                      'col-md-6': onSave == false
                    }"
                  >
                    <input
                      class="form-control"
                      type="text"
                      value="{{ columns }}"
                      disabled
                    />
                  </div>
                  <div
                    [ngClass]="{
                      'col-md-4': onSave == true,
                      'col-md-6': onSave == false
                    }"
                  >
                    <select
                      class="form-select btn_psecondary get-map-col-name"
                      tabindex="-1"
                      *ngIf="i < mapcolumns.length"
                    >
                      <option
                        *ngFor="let control of formControls"
                        [value]="control.controlType"
                        [attr.data-id]="control.id"
                        [selected]="
                          mapcolumns[i][0]['qfcol'] == control.fieldName
                        "
                      >
                        {{ control.fieldName }}
                      </option>
                    </select>

                    <select
                      class="form-select btn_psecondary get-map-col-name"
                      tabindex="-1"
                      *ngIf="i >= mapcolumns.length"
                    >
                      <option
                        *ngFor="let control of formControls"
                        [value]="control.controlType"
                        [attr.data-id]="control.id"
                      >
                        {{ control.fieldName }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4" *ngIf="onSave">
                    <select
                      class="form-select btn_psecondary slc-datatype"
                      *ngIf="i >= mapcolumns.length"
                    >
                      <option
                        *ngFor="let option of mapcolumnsdatatype"
                        [value]="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <select
                      class="form-select btn_psecondary slc-datatype"
                      *ngIf="i < mapcolumns.length"
                    >
                      <option
                        *ngFor="let option of mapcolumnsdatatype"
                        [value]="option.value"
                        [selected]="
                          mapcolumns[i][0]['datatype'] == option.value
                        "
                      >
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3"></div>
              </div>
              <div
                class="col-xl-10 fv-row fv-plugins-icon-container text-center"
              ></div>
            </div>
          </div>
        </div>
        <div class="row mb-2 mt-2" [ngClass]="slchub ? 'show' : 'hide'">
          <div class="col-md-12">
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Method</div>
              </div>
              <div class="col-xl-8 mt-5 fv-row fv-plugins-icon-container mb-5">
                <mat-radio-group
                  start="align"
                  (change)="sntoggleMethod($event)"
                  class="url"
                >
                  <mat-radio-button
                    *ngFor="let option of snmethodtypeOptions"
                    [checked]="option.checked"
                    value="{{ option.value }}"
                    >{{ option.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-xl-2 fv-row text-right" *ngIf="!ispublished">
                <a
                  href="javascript:void(0);"
                  mat-raised-button
                  color="primary"
                  (click)="validateSNapi()"
                  >Validate</a
                >
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Url</div>
              </div>
              <div class="col-xl-8 fv-row fv-plugins-icon-container">
                <mat-form-field appearance="outline" class="url">
                  <mat-label>Url</mat-label>
                  <input matInput formControlName="snapiurl" />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Header</div>
              </div>
              <div class="col-xl-8 fv-row fv-plugins-icon-container">
                <textarea
                  formControlName="snapiheaders"
                  rows="5"
                  cols="40"
                  class="form-control apiheader"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Params</div>
              </div>
              <div class="col-xl-8 fv-row fv-plugins-icon-container">
                <textarea
                  formControlName="snapiparams"
                  rows="5"
                  cols="40"
                  class="form-control apiheader"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3">Columns</div>
              </div>
              <div class="col-xl-7 fv-row fv-plugins-icon-container">
                <mat-form-field appearance="outline" class="apicolumn">
                  <mat-label>Columns</mat-label>
                  <input matInput formControlName="apicolumns" />
                </mat-form-field>
              </div>
              <div class="col-xl-2">
                <div class="fs-6 fw-bold ml-1 mt-1 mb-3">
                  <button
                    type="button"
                    class="btn btn-outline-info waves-effect px-3"
                    (click)="colMaping()"
                  >
                    <span
                      ><img
                        src="../../../assets/media/images/network.png"
                        class="icon-mapping"
                        alt="Map Columns"
                        title="Map Columns"
                    /></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3"></div>
              </div>
              <div class="col-xl-8 fv-row fv-plugins-icon-container">
                <div
                  class="row mb-1"
                  *ngFor="let columns of arrcolumns; let i = index"
                >
                  <div class="col-md-6">
                    <input
                      class="form-control"
                      type="text"
                      value="{{ columns }}"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <select
                      class="form-select btn_psecondary map-col-name"
                      tabindex="-1"
                      *ngIf="i < mapcolumns.length"
                    >
                      <option
                        *ngFor="let control of formControls"
                        [value]="control.controlType"
                        [attr.data-id]="control.id"
                        [selected]="
                          mapcolumns[i][0]['qfcol'] == control.fieldName
                        "
                      >
                        {{ control.fieldName }}
                      </option>
                    </select>

                    <select
                      class="form-select btn_psecondary map-col-name"
                      tabindex="-1"
                      *ngIf="i >= mapcolumns.length"
                    >
                      <option
                        *ngFor="let control of formControls"
                        [value]="control.controlType"
                        [attr.data-id]="control.id"
                      >
                        {{ control.fieldName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-xl-2 text-right">
                <div class="fs-6 fw-bold mt-5 mb-3"></div>
              </div>
              <div
                class="col-xl-10 fv-row fv-plugins-icon-container text-center"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <a href="javascript:void(0);" mat-raised-button (click)="close()">Close</a>
  <a
    *ngIf="beginid == 0 && !ispublished"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform('runscript', 'save')"
    >Save</a
  >
  <a
    *ngIf="beginid != 0 && !ispublished"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform('runscript', 'update')"
    >Update</a
  >
</mat-dialog-actions>
