import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import $ from "jquery";
import RecpTagify from "../../../assets/js/tagify.min";
import tinymce from "../../../assets/plugins/custom/tinymce/tinymce.bundle";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TagData, TagifyService, TagifySettings } from "ngx-tagify";
import { UnsavedComponent } from "../unsaved/unsaved.component";
import { MatRadioChange } from "@angular/material/radio";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";

@Component({
  selector: "app-sendemail",
  templateUrl: "./sendemail.component.html",
  styleUrls: ["./sendemail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SendemailComponent implements OnInit {
  form: FormGroup;
  beginid: any;
  description: string;
  formid: any;
  workflowid: any;
  nodeid: any;
  nodetype: any;
  emailsubject: string;
  emailcontent: string;
  sendername: string;
  replyto: string;
  erecipient: any;
  ispdfattach: boolean;
  ishiddenfields: boolean;
  senderemail: string;
  formControls: any = [];
  savedata: any;
  updatedata: any;
  panelOpenState: boolean = true;
  tabSelected = 0;
  tabtotal = 0;
  activateScript: boolean = true;
  formname: string;
  tags: any = [];
  tabsclick: boolean = false;
  ispdfattachtype: string;
  ispdfattachapi: boolean = true;
  ispublished: boolean = false;
  strsubject: any;
  confirmFormDeleteDialogRef = null;
  closedialog: boolean = true;
  recipientEmail = [];
  isotherslist: any;
  userapp: boolean = false;
  groupapp: boolean = false;
  isothers: boolean = true;
  userList: any = [];
  userGroups: any = [];
  slcUserList: any = [];
  slcUserGroups: any = [];
  taguser: any;
  userTab: boolean = false;
  groupTab: boolean = false;
  recipientType: any;
  userOnEdit: any;
  groupOnEdit: any;
  formData = new Subject();
  optOthers: any;
  testdata: any;

  tagsettings: TagifySettings = {
    mode: "mix",
    duplicates: false,
  };

  constructor(
    private fb: FormBuilder,
    private api: WorkFlowService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SendemailComponent>,
    private tagifyService: TagifyService,
    @Inject(MAT_DIALOG_DATA)
    {
      beginid,
      description,
      formid,
      workflowid,
      nodeid,
      nodetype,
      emailsubject,
      emailcontent,
      sendername,
      replyto,
      erecipient,
      optOthers,
      recipientType,
      ispdfattach,
      ispdfattachtype,
      ishiddenfields,
      senderemail,
      ispublished,
    }
  ) {
    this.beginid = beginid;
    this.description = description;
    this.formid = formid;
    this.workflowid = workflowid;
    this.nodeid = nodeid;
    this.nodetype = nodetype;
    this.emailsubject = emailsubject;
    this.emailcontent = emailcontent;
    this.sendername = sendername;
    this.replyto = replyto;
    this.erecipient = erecipient;
    this.optOthers = optOthers;
    this.recipientType = recipientType;
    this.ispdfattach = ispdfattach;
    this.ispdfattachtype = ispdfattachtype;
    this.ishiddenfields = ishiddenfields;
    this.senderemail = senderemail;
    this.formname = localStorage.getItem("formname");
    this.ispublished = ispublished;

    this.form = fb.group({
      beginid: [beginid],
      description: [description, Validators.required],
      formid: [formid],
      workflowid: [workflowid],
      nodeid: [nodeid],
      emailsubject: [emailsubject, Validators.required],
      emailcontent: [emailcontent],
      sendername: [sendername],
      replyto: [replyto],
      erecipient: [erecipient],
      optOthers: [optOthers],
      recipientType: [recipientType],
      ispdfattach: [ispdfattach],
      ispdfattachtype: [ispdfattachtype],
      ishiddenfields: [ishiddenfields],
      senderemail: [senderemail],
      ispublished: [ispublished],
    });
  }

  ngOnInit(): void {
    this.getControls();
    this.description = "Send Email";
    this.strsubject = this.emailsubject;
    localStorage.removeItem("emailsubject");
    localStorage.removeItem("emailcontent");
    if (this.beginid == 0) {
      this.isotherslist = "formsubmitter";
    }
    if (this.isothers && this.beginid == 0) this.recipientType = "others";

    if (this.beginid != 0) {
      this.isotherslist = this.erecipient;
      switch (this.recipientType) {
        case "users":
          this.userapp = true;
          this.groupapp = false;
          this.isothers = false;
          this.userOnEdit = [];
          this.erecipient.forEach((element) => {
            this.userOnEdit.push(element.email);
          });
          break;
        case "groups":
          this.groupapp = true;
          this.userapp = false;
          this.isothers = false;
          this.groupOnEdit = [];
          this.erecipient.forEach((element) => {
            this.groupOnEdit.push(element.role);
          });
          break;
        case "others":
          this.isothers = true;
          this.userapp = false;
          this.groupapp = false;
          break;
        default:
          break;
      }
    }
    // if (this.beginid == 0) {
    //   localStorage.setItem("subjectlength", "0");
    // } else {
    //   localStorage.setItem("subjectlength", "10");
    // }
    this.getUserList();
    this.getUserGroups();
  }

  getControls() {
    this.api.getControls(this.formid).subscribe((res: any) => {
      this.ispdfattachapi = res.form[0].isPdfOutput;
      this.formData.next(res.form[0]);
      this.testdata = res.form[0];
      this.activateEditor(this, res.form[0]);
      for (let i = 0; i < res.controls.length; i++) {
        if (
          res.controls[i].controlType != "header" &&
          res.controls[i].controlType != "button" &&
          res.controls[i].controlType != "sectionbreak" &&
          res.controls[i].controlType != "pagenavigator" &&
          res.controls[i].controlType != "social" &&
          res.controls[i].controlType != "divider" &&
          res.controls[i].controlType != "formheader" &&
          res.controls[i].controlType != "footer" &&
          res.controls[i].controlType != "image" &&
          res.controls[i].controlType != "typoparagraph" &&
          res.controls[i].controlType != "twocolumntext" &&
          res.controls[i].controlType != "imagetext" &&
          res.controls[i].controlType != "jumbotron" &&
          res.controls[i].controlType != "features" &&
          res.controls[i].controlType != "servicelist" &&
          res.controls[i].controlType != "pricetable" &&
          res.controls[i].controlType != "list" &&
          res.controls[i].controlType != "groups" &&
          res.controls[i].controlType != "tablelayout" &&
          res.controls[i].controlType != "groupcontrol" &&
          res.controls[i].controlType != "rankingchoice" &&
          res.controls[i].controlType != "age" &&
          res.controls[i].controlType != "signature" &&
          res.controls[i].controlType != "barcode" &&
          res.controls[i].controlType != "expressionbox" &&
          res.controls[i].controlType != "recaptcha" &&
          res.controls[i].controlType != "fingerprint" &&
          res.controls[i].controlType != "Takephoto" &&
          res.controls[i].controlType != "geolocation" &&
          res.controls[i].controlType != "locationcoordinates" &&
          res.controls[i].controlType != "video" &&
          res.controls[i].controlType != "videoRecorder" &&
          res.controls[i].controlType != "fileupload" &&
          res.controls[i].controlType != "imagecropper" &&
          res.controls[i].controlType != "pagebreak"
        ) {
          this.formControls.push(res.controls[i]);
        }
        if (res.controls[i].controlType == "email") {
          this.recipientEmail.push(res.controls[i]);
        }
      }
    });
  }

  changeRecpEmail(event) {
    this.isotherslist =
      event.target["options"][event.target["options"].selectedIndex].value;
    this.form.patchValue({ optOthers: "" });
  }

  ngAfterViewInit(): void {
    if (typeof tinymce !== "undefined") {
      tinymce.remove();
    }
    let _self = this;
    this.activateTagify(_self, this.tabsclick);
  }

  tabChanged(tabEvent) {
    let _self = this;
    this.tabSelected = tabEvent.index;
    this.tabsclick = true;
    this.activatetags();
    if (this.tabSelected == 1) {
      if (!this.userTab) {
        this.userTab = true;
        this.bindTagifyUserList(this.userList);
      }
      if (!this.groupTab) {
        this.groupTab = true;
        this.bindTagifyUserGroups(this.userGroups);
      }
    }
    if (this.tabSelected == 0) {
      this.tabtotal = 0;
      // if (!localStorage.getItem("subjectlength")) {
      //   localStorage.setItem(
      //     "subjectlength",
      //     $(document).find("tagify").text()
      //   );
      // }
    } else {
      this.tabtotal += 1;
    }

    if (this.tabtotal <= 1) {
      localStorage.setItem(
        "emailsubject",
        this.tagifyService.get("emailsubject").DOM.input.textContent
      );
      localStorage.setItem(
        "emailcontent",
        tinymce.get("qf_appr_cust_email").getContent({ format: "html" })
      );
      if (typeof tinymce !== "undefined") {
        tinymce.remove();
      }
      this.activateEditor(_self, this.testdata);
    }
    this.activateTagify(_self, this.tabSelected);
  }

  tabNext(event) {
    let tabIndex = this.tabSelected;
    if (tabIndex < 2) {
      tabIndex = Number(tabIndex) + 1;
      this.tabSelected = tabIndex;
      this.tabsclick = true;
    }
    if (tabIndex == 1) {
      if (!this.userTab && this.recipientType == "users") {
        this.userTab = true;
        this.bindTagifyUserList(this.userList);
      }
      if (!this.groupTab && this.recipientType == "groups") {
        this.groupTab = true;
        this.bindTagifyUserGroups(this.userGroups);
      }
    }
  }

  tabBack(event) {
    let tabIndex = this.tabSelected;
    if (tabIndex > 0) {
      tabIndex = Number(tabIndex) - 1;
      this.tabSelected = tabIndex;
      this.tabsclick = true;
    }
    if (this.tabSelected == 1) {
      if (!this.userTab && this.recipientType == "users") {
        this.userTab = true;
        this.bindTagifyUserList(this.userList);
      }
      if (!this.groupTab && this.recipientType == "groups") {
        this.groupTab = true;
        this.bindTagifyUserGroups(this.userGroups);
      }
    }
  }

  changePdf(event) {
    if (event.target.checked) {
      this.ispdfattach = true;
    } else {
      this.ispdfattach = false;
    }
  }

  activateEditor(_self, res) {
    tinymce.init({
      selector: "textarea#qf_appr_cust_email",
      height: 500,
      plugins: "link, preview, code",
      toolbar:
        "undo redo | styleselect | bold italic link | alignleft aligncenter alignright alignjustify | outdent indent  | preview code | mybutton",
      link_list: [
        {
          title: "Form Link",
          value:
            environment.qformFrontEndUrl +
            "/#/qforms/entry-edit-resume/" +
            res["_id"] +
            "/{_id}/{__qf__token_saveAndResumeLater}",
        },
      ],
      setup: function (editor) {
        var toggleState = false;
        editor.ui.registry.addMenuButton("mybutton", {
          text: "Form Fields",
          fetch: function (callback) {
            var items = [];
            items.push({
              type: "menuitem",
              text: "Form ID",
              onAction: function () {
                editor.insertContent("&nbsp;{" + res["_id"] + "}");
              },
            });
            items.push({
              type: "menuitem",
              text: "Form Name",
              onAction: function () {
                editor.insertContent("" + res["label"] + "");
              },
            });
            _self.formControls.forEach((ctrl) => {
              items.push({
                type: "menuitem",
                text: ctrl.fieldName,
                onAction: function () {
                  editor.insertContent(
                    "&nbsp;{" + ctrl.controlType + "_" + ctrl.id + "}"
                  );
                },
              });
            });

            callback(items);
          },
        });
      },
      content_style:
        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      init_instance_callback: function (editor) {
        editor.on("click", function (e) {
          localStorage.removeItem("emailcontent");
        });
      },
    });
  }

  activateTagify(_self, tabclick) {
    $(document)
      .off("click", ".frmfields")
      .on("click", ".frmfields", function () {
        _self.tags = [];
        //var i = $(this).find(".frm-fields").text();
        var i = $(this).attr("data-id");
        i = i.toLowerCase();
        i = i.replace(/ /g, "");
        i = "[[" + i + "]]";
        i = _self.tags.push(i);
        _self.tagifyService.get("emailsubject").addTags(_self.tags);
        $(document).find(".dropdown-menu").hasClass("show")
          ? $(document).find(".dropdown-menu").removeClass("show")
          : "";
        var el = document.getElementsByClassName("tagify__input")[0];
        var range = document.createRange();
        var sel = window.getSelection();
        range.setStart(el.childNodes[0], 0);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      });
    $(document)
      .off("click", ".tagify__tag__removeBtn")
      .on("click", ".tagify__tag__removeBtn", function (e) {
        var velement = $(this).parent("tag").attr("value");
        _self.tags.forEach((element, index) => {
          if (element == velement) _self.tags.splice(index, 1);
        });
      });

    if (_self.beginid != 0 && tabclick === false) {
      $(document)
        .find(".tagify__tag-text")
        .each(function () {
          _self.tags.push($(this).text());
        });
    }
  }

  changeEvent(evt) {
    this.strsubject = evt.target.innerText;
  }

  getUserList() {
    this.api.getUserlist(this.formid).subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        //this.userList.push(res[i].email);
        this.userList.push({
          value: res[i].email,
          id: res[i]._id,
          email: res[i].email,
          name: res[i].name,
        });
      }
    });
  }
  getUserGroups() {
    this.api.getUserGroups(this.formid).subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        //this.userGroups.push(res[i].role);
        this.userGroups.push({
          value: res[i].role,
          id: res[i]._id,
          role: res[i].role,
          type: res[i].type,
        });
      }
    });
  }

  bindTagifyUserList(data) {
    var tagify = new RecpTagify(document.querySelector('[name="tagsuser"]'), {
      whitelist: data,
      userList: this.slcUserList,
      dropdown: {
        searchKeys: ["name", "email"],
        enabled: 0,
        closeOnSelect: !1,
        position: "text",
        highlightFirst: true,
      },
    });
    tagify.on("add", (evt) => {
      this.slcUserList.push({
        id: evt.detail.data.id,
        email: evt.detail.data.email,
        name: evt.detail.data.name,
        value: evt.detail.data.value,
      });
    });
    tagify.on("remove", (evt) => {
      this.slcUserList.forEach((element, index) => {
        if (element.id == evt.detail.data.id) {
          this.slcUserList.splice(index, 1);
        }
      });
    });
  }

  bindTagifyUserGroups(data) {
    var tagify = new RecpTagify(document.querySelector('[name="tagsgroups"]'), {
      whitelist: data,
      dropdown: {
        searchKeys: ["role"],
        enabled: 0,
        closeOnSelect: !1,
        position: "text",
        highlightFirst: true,
      },
    });
    tagify.on("add", (evt) => {
      this.slcUserGroups.push({
        id: evt.detail.data.id,
        role: evt.detail.data.role,
        type: evt.detail.data.type,
        value: evt.detail.data.value,
      });
    });
    tagify.on("remove", (evt) => {
      this.slcUserGroups.forEach((element, index) => {
        if (element.id == evt.detail.data.id) {
          this.slcUserGroups.splice(index, 1);
        }
      });
    });
  }

  radioChange(event: MatRadioChange) {
    var tagsgroup = document.querySelector('[id="ptagsgroups"]');
    var tagsuser = document.querySelector('[id="ptagsuser"]');
    switch (event.value) {
      case "groups":
        this.recipientType = "groups";
        tagsgroup.classList.remove("hide");
        tagsuser.classList.add("hide");
        this.groupapp = true;
        this.userapp = false;
        this.isothers = false;
        this.form.patchValue({ optOthers: "" });
        break;

      case "users":
        this.recipientType = "users";
        tagsgroup.classList.add("hide");
        tagsuser.classList.remove("hide");
        this.userapp = true;
        this.groupapp = false;
        this.isothers = false;
        this.form.patchValue({ optOthers: "" });
        break;
      case "others":
        this.recipientType = "others";
        tagsgroup.classList.add("hide");
        tagsuser.classList.add("hide");
        this.isothers = true;
        this.groupapp = false;
        this.userapp = false;

        break;

      default:
        break;
    }
  }

  saveform(formstate) {
    let validform = true;
    if (this.tabSelected == 0) {
      //this.strsubject = new String($(document).find("tagify").text());
      //this.strsubject = this.strsubject.trim();
    } else {
      //this.strsubject = localStorage.getItem("subjectlength");
    }

    if (this.strsubject.length <= 0 || this.strsubject == "0") {
      this.toastr.error("Email Subject cannot be empty! ", "Error!");
      validform = false;
    }

    if (this.form.value.sendername == "") {
      this.toastr.error("Sender Name cannot be empty! ", "Error!");
      validform = false;
    }

    if (this.form.value.senderemail == "") {
      this.toastr.error("Sender Email cannot be empty! ", "Error!");
      validform = false;
    }
    if (this.recipientEmail.length == 0) {
      //if (this.form.value.erecipient == "") {
      if (this.isotherslist == "") {
        this.toastr.error("Recipient Email cannot be empty! ", "Error!");
        validform = false;
      }
    }
    if (this.recipientEmail.length > 0) {
      if (this.isotherslist == "") {
        this.toastr.error("Select Recipeient Email! ", "Error!");
        validform = false;
      }
    }

    if (this.isotherslist == "optOthers" && this.form.value.optOthers == "") {
      this.toastr.error("Others Email cannot be empty! ", "Error!");
      validform = false;
    }

    switch (this.recipientType) {
      case "users":
        this.erecipient = [];
        this.erecipient = this.slcUserList;
        break;
      case "groups":
        this.erecipient = this.slcUserGroups;
        break;
      case "others":
        if (this.isotherslist == "optOthers") {
          this.erecipient =
            this.isotherslist == "optOthers"
              ? this.form.value.optOthers
              : this.isotherslist;
          if (this.isotherslist == "optOthers") {
            this.recipientType = this.isotherslist;
          }
        } else {
          this.erecipient =
            this.isotherslist == "formsubmitter"
              ? this.api.userEmail
              : this.isotherslist;
        }
        break;
      case "optOthers":
        this.erecipient =
          this.isotherslist == "optOthers"
            ? this.form.value.optOthers
            : this.isotherslist;
        if (this.isotherslist == "optOthers") {
          this.recipientType = this.isotherslist;
        }
        break;

      default:
        break;
    }

    if (validform) {
      if (this.beginid === 0 && formstate == "save") {
        this.getsavefields("save");
      } else {
        this.getsavefields("update");
      }
    }

    //this.dialogRef.close(this.form.value);
  }

  activatetags() {
    let objEmail = this.tagifyService.get("emailsubject");
    let stremail = objEmail.DOM.input.textContent;
    for (let x = 0; x < objEmail.value.length; x++) {
      if (stremail.indexOf(objEmail.value[x].value) !== -1) {
        if (objEmail.value[x].value.indexOf("[") !== -1) {
        } else {
          $(document)
            .find(".tagify__tag-text")
            .each(function (index) {
              if (x == index) {
                $(this).html("[[" + objEmail.value[x].value + "]]");
              }
            });
        }
      }
    }
  }

  getsavefields(formmode) {
    this.activatetags();
    if (this.tabSelected == 0) {
      localStorage.setItem(
        "emailsubject",
        this.tagifyService.get("emailsubject").DOM.input.textContent
      );
      localStorage.setItem(
        "emailcontent",
        tinymce.get("qf_appr_cust_email").getContent({ format: "html" })
      );
    }
    this.savedata = {
      beginid: this.beginid,
      workflowid: this.form.value.workflowid,
      formid: this.form.value.formid,
      nodeid: this.form.value.nodeid,
      nodetype: this.nodetype,
      emailsubject: localStorage.getItem("emailsubject"),
      emailcontent: localStorage.getItem("emailcontent"),
      sendername: this.form.value.sendername,
      replyto: this.form.value.replyto,
      //erecipient: this.form.value.erecipient,
      recipienttype: this.recipientType,
      erecipient: this.erecipient,
      ispdfattach: this.form.value.ispdfattach,
      ispdfattachtype:
        this.ispdfattach == true
          ? this.ispdfattachapi == false
            ? "default"
            : "template"
          : "false",
      ishiddenfields: this.form.value.ishiddenfields,
      senderemail: this.form.value.senderemail,
      status: true,
      userid: this.api.getUserId(),
    };
    switch (formmode) {
      case "save":
        this.api.saveEmail(this.savedata).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success("Notification saved successfully", "Success");
              this.dialogRef.close({
                workflowname:
                  this.form.value.emailsubject.indexOf("_") !== -1
                    ? "Send Email"
                    : this.form.value.emailsubject,
              });
            } else {
              this.toastr.error("Notification failed! ", "Error!");
            }
            this.strsubject = "";
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
        break;
      case "update":
        this.api.updateEmail(this.savedata, this.savedata.beginid).subscribe(
          (res: any) => {
            this.ngxService.stop();
            if (res && Number(res.responseCode) === 201) {
              this.toastr.success(
                "Notification updated successfully",
                "Success"
              );
              this.dialogRef.close({
                workflowname:
                  this.form.value.emailsubject.indexOf("_") !== -1
                    ? "Send Email"
                    : this.form.value.emailsubject,
              });
            } else {
              this.toastr.error("Notification failed! ", "Error!");
            }
            this.strsubject = "";
            this.ngxService.stop();
          },
          (err) => {
            this.ngxService.stop();
            console.log(err);
          }
        );
        break;
      default:
        break;
    }
  }

  close() {
    // if (this.tabSelected == 0) {
    //   //this.strsubject = new String($(document).find("tagify").text());
    //   //this.strsubject = this.strsubject.trim();
    // } else {
    //   //this.strsubject = new String(localStorage.getItem("subjectlength"));
    // }
    if (this.strsubject.length > 0 && this.beginid == 0 && this.form.dirty) {
      this.closedialog = false;
      this.confirmFormDeleteDialogRef = this.dialog.open(UnsavedComponent, {
        width: "400px",
        id: "unsavedchanges",
        disableClose: true,
        data: {
          header: "Unsaved Changes?",
          content:
            "You have unsaved Changes on this workflow stage. Do you want to continue?",
          postivebtnLabel: "Ok",
          negativeBtnLabel: "Cancel",
        },
      });

      this.confirmFormDeleteDialogRef.afterClosed().subscribe(
        (result) => {
          if (result && !result.cancel) {
            this.dialogRef.close();
          }
        },
        (error) => {
          console.error("Error while closing the dialog ", error);
        }
      );
    }
    if (this.closedialog) {
      this.dialogRef.close();
    }
  }
}
