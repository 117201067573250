import {
  APP_ID,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { WorkFlowService } from "src/app/services/work-flow.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import $ from "jquery";
import Tagify from "../../../assets/js/tagify.min";
import tinymce from "../../../assets/plugins/custom/tinymce/tinymce.bundle";
import { MatRadioButton, MatRadioChange } from "@angular/material/radio";
import { UnsavedComponent } from "../unsaved/unsaved.component";

@Component({
  selector: "app-runscript",
  templateUrl: "./runscript.component.html",
  styleUrls: ["./runscript.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RunscriptComponent implements OnInit {
  form: FormGroup;
  beginid: any;
  description: string;
  formid: any;
  workflowid: any;
  nodeid: any;
  nodetype: any;
  workflowname: string;
  workflowstage: string;
  scriptplace: string;
  runscript: any;
  scripttype: string = "";
  apimethodtype: any;
  apiurl: any;
  apiheaders: any;
  apiparams: any;
  panelOpenState: boolean = false;
  ruleid = 0;
  begindetails: any;
  data: any;
  align = "start";
  isgeneral: boolean = false;
  isMethod: any;
  hubisMethod: any;
  formname: string;
  ispublished: boolean = false;
  isservicenow: boolean = false;
  snapimethodtype: any;
  snapiurl: any;
  snapiheaders: any;
  snapiparams: any;
  apicolumns: any;
  httprequest: boolean = false;
  slchub: boolean = false;
  issalesforce: boolean = false;
  arrcolumns: any[] = [];
  formControls: any = [];
  mapcolumns: any[] = [];
  apicolindex: any;
  paramcolumns: string = "";
  triggerbindby: string = "";
  triggerCtrl: string = "";
  generalSlcTrigger: any[] = [];
  isgeneralSlcTrigger: boolean = false;

  @ViewChild("apimethod")
  apimethod: MatRadioButton;
  apiresult: any = "";
  onSave: boolean = false;
  confirmFormDeleteDialogRef = null;
  closedialog: boolean = true;

  public scripttypeOptions: any = [
    { label: "Generic", value: "general", checked: true },
    { label: "HTTPRequest", value: "httprequest", checked: false },
    { label: "Integration Hub", value: "integration", checked: false },
  ];
  public methodtypeOptions: any = [
    { label: "GET", value: "get", checked: true },
    { label: "POST", value: "post", checked: false },
    { label: "PUT", value: "put", checked: false },
    { label: "DELETE", value: "delete", checked: false },
  ];

  public snmethodtypeOptions: any = [
    { label: "GET", value: "get", checked: true },
    { label: "POST", value: "post", checked: false },
  ];

  public mapcolumnsdatatype: any = [
    { label: "String", value: "string", selected: true },
    { label: "Number", value: "number", selected: false },
    { label: "Boolean", value: "boolean", selected: false },
    { label: "Float", value: "float", selected: false },
    { label: "Date", value: "date", selected: false },
    { label: "Time", value: "time", selected: false },
    { label: "Date Time", value: "datetime", selected: false },
  ];

  constructor(
    private fb: FormBuilder,
    private api: WorkFlowService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    public router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RunscriptComponent>,
    @Inject(MAT_DIALOG_DATA)
    {
      beginid,
      description,
      formid,
      workflowid,
      nodeid,
      nodetype,
      workflowname,
      workflowstage,
      scriptplace,
      runscript,
      scripttype,
      apimethodtype,
      apiurl,
      apiheaders,
      apiparams,
      apicolumns,
      mapcolumns,
      apicolindex,
      paramcolumns,
      triggerbindby,
      ispublished,
    }
  ) {
    this.beginid = beginid;
    this.description = description;
    this.formid = formid;
    this.workflowid = workflowid;
    this.nodeid = nodeid;
    this.nodetype = nodetype;
    this.workflowname = workflowname;
    this.workflowstage = workflowstage;
    this.scriptplace = scriptplace;
    this.runscript = runscript;
    this.scripttype = scripttype;
    this.apimethodtype = apimethodtype;
    this.apiurl = apiurl;
    this.apiheaders = apiheaders;
    this.apiparams = apiparams;

    // this.apimethodtype = apimethodtype == "httprequest" ? apimethodtype : "";
    // this.apiurl = apiurl == "httprequest" ? apiurl : "";
    // this.apiheaders = apiheaders == "httprequest" ? apiheaders : "";
    // this.apiparams = apiparams == "httprequest" ? apiparams : "";

    this.formname = localStorage.getItem("formname");
    this.snapimethodtype =
      scripttype == "servicenow" || scripttype == "salesforce"
        ? apimethodtype
        : "";
    this.snapiurl =
      scripttype == "servicenow" || scripttype == "salesforce" ? apiurl : "";
    this.snapiheaders =
      scripttype == "servicenow" || scripttype == "salesforce"
        ? apiheaders
        : "";
    this.snapiparams =
      scripttype == "servicenow" || scripttype == "salesforce" ? apiparams : "";
    this.apicolumns = apicolumns;
    this.mapcolumns = mapcolumns;
    this.apicolindex = apicolindex;
    this.paramcolumns = paramcolumns;
    this.triggerbindby = triggerbindby;
    this.ispublished = ispublished;

    this.form = fb.group({
      beginid: [beginid],
      description: [description, Validators.required],
      formid: [formid],
      workflowid: [workflowid],
      nodeid: [nodeid],
      workflowname: [workflowname],
      workflowstage: [workflowstage],
      scriptplace: [scriptplace],
      runscript: [runscript],
      scripttype: [scripttype],
      apimethodtype: scripttype == "httprequest" ? [apimethodtype] : "",
      apiurl: scripttype == "httprequest" ? [apiurl] : "",
      apiheaders: scripttype == "httprequest" ? [apiheaders] : "",
      apiparams: scripttype == "httprequest" ? [apiparams] : "",
      snapimethodtype:
        scripttype == "servicenow" || scripttype == "salesforce"
          ? [apimethodtype]
          : "",
      snapiurl:
        scripttype == "servicenow" || scripttype == "salesforce"
          ? [apiurl]
          : "",
      snapiheaders:
        scripttype == "servicenow" || scripttype == "salesforce"
          ? [apiheaders]
          : "",
      snapiparams:
        scripttype == "servicenow" || scripttype == "salesforce"
          ? [apiparams]
          : "",
      apicolumns: [apicolumns],
      mapcolumns: [mapcolumns],
      apicolindex: [apicolindex],
      paramcolumns: [paramcolumns],
      triggerbindby: [triggerbindby],
      ispublished: [ispublished],
    });

    //if (this.workflowstage != "") {
    this.form.controls.workflowstage.disable();
    //}

    this.isgeneral = true;
    switch (this.scripttype) {
      case "general":
        this.scripttypeOptions[0].checked = true;
        this.scripttypeOptions[1].checked = false;
        this.scripttypeOptions[2].checked = false;
        this.isgeneral = true;
        this.httprequest = false;
        this.slchub = false;
        break;
      case "httprequest":
        this.scripttypeOptions[0].checked = false;
        this.scripttypeOptions[1].checked = true;
        this.scripttypeOptions[2].checked = false;
        this.isgeneral = false;
        this.httprequest = true;
        this.slchub = false;
        break;
      case "servicenow":
        this.scripttypeOptions[0].checked = false;
        this.scripttypeOptions[1].checked = false;
        this.scripttypeOptions[2].checked = true;
        this.isgeneral = false;
        this.httprequest = false;
        this.slchub = true;
        this.isservicenow = true;
        break;
      case "salesforce":
        this.scripttypeOptions[0].checked = false;
        this.scripttypeOptions[1].checked = false;
        this.scripttypeOptions[2].checked = true;
        this.isgeneral = false;
        this.httprequest = false;
        this.slchub = true;
        this.issalesforce = true;
        break;

      default:
        break;
    }

    switch (this.apimethodtype) {
      case "get":
        this.methodtypeOptions[0].checked = true;
        this.methodtypeOptions[1].checked = false;
        this.methodtypeOptions[2].checked = false;
        this.methodtypeOptions[3].checked = false;
        break;
      case "post":
        this.methodtypeOptions[0].checked = false;
        this.methodtypeOptions[1].checked = true;
        this.methodtypeOptions[2].checked = false;
        this.methodtypeOptions[3].checked = false;
        break;
      case "put":
        this.methodtypeOptions[0].checked = false;
        this.methodtypeOptions[1].checked = false;
        this.methodtypeOptions[2].checked = true;
        this.methodtypeOptions[3].checked = false;
        break;
      case "delete":
        this.methodtypeOptions[0].checked = false;
        this.methodtypeOptions[1].checked = false;
        this.methodtypeOptions[2].checked = false;
        this.methodtypeOptions[3].checked = true;
        break;

      default:
        break;
    }
    switch (this.snapimethodtype) {
      case "get":
        this.snmethodtypeOptions[0].checked = true;
        this.snmethodtypeOptions[1].checked = false;
        break;
      case "post":
        this.snmethodtypeOptions[0].checked = false;
        this.snmethodtypeOptions[1].checked = true;
        break;

      default:
        break;
    }

    if (typeof this.apimethodtype !== "undefined") {
      this.isMethod = this.apimethodtype;
    }
    if (typeof this.snapimethodtype !== "undefined") {
      this.hubisMethod = this.snapimethodtype;
    }
    if (this.scriptplace == "onsave") {
      this.onSave = true;
    }

    if (
      this.scripttype == "general" &&
      this.paramcolumns != "" &&
      this.triggerbindby != ""
    ) {
      this.isgeneralSlcTrigger = true;
    }
  }

  ngOnInit(): void {
    if (this.description === "runscript") {
      this.description = "Run Script";
    }
    if (this.beginid !== 0) {
      if (this.scripttype != "general") {
        this.mapcolumns.forEach((element) => {
          this.arrcolumns.push(element[0].apicol);
        });
      }
      if (this.triggerbindby !== null) {
        this.triggerCtrl = "dropdown";
      }
    }
    this.getControls();
  }

  getControls() {
    this.api.getControls(this.formid).subscribe((res: any) => {
      for (let i = 0; i < res.controls.length; i++) {
        if (
          //res.controls[i].controlType != "groupcontrol" &&
          res.controls[i].controlType != "header" &&
          res.controls[i].controlType != "button" &&
          res.controls[i].controlType != "sectionbreak" &&
          res.controls[i].controlType != "pagenavigator" &&
          res.controls[i].controlType != "social" &&
          res.controls[i].controlType != "divider" &&
          res.controls[i].controlType != "formheader" &&
          res.controls[i].controlType != "footer" &&
          res.controls[i].controlType != "image" &&
          res.controls[i].controlType != "typoparagraph" &&
          res.controls[i].controlType != "twocolumntext" &&
          res.controls[i].controlType != "imagetext" &&
          res.controls[i].controlType != "jumbotron" &&
          res.controls[i].controlType != "features" &&
          res.controls[i].controlType != "servicelist" &&
          res.controls[i].controlType != "pricetable" &&
          res.controls[i].controlType != "list" &&
          res.controls[i].controlType != "groups" &&
          res.controls[i].controlType != "tablelayout" &&
          res.controls[i].controlType != "groupcontrol" &&
          res.controls[i].controlType != "rankingchoice" &&
          res.controls[i].controlType != "age" &&
          res.controls[i].controlType != "signature" &&
          res.controls[i].controlType != "barcode" &&
          res.controls[i].controlType != "expressionbox" &&
          res.controls[i].controlType != "recaptcha" &&
          res.controls[i].controlType != "fingerprint" &&
          res.controls[i].controlType != "Takephoto" &&
          res.controls[i].controlType != "geolocation" &&
          res.controls[i].controlType != "locationcoordinates" &&
          res.controls[i].controlType != "video" &&
          // res.controls[i].controlType != "videoRecorder" &&
          // res.controls[i].controlType != "fileupload" &&
          res.controls[i].controlType != "imagecropper" &&
          res.controls[i].controlType != "pagebreak"
          //&& res.controls[i].controlType != "fileupload"
        ) {
          this.formControls.push(res.controls[i]);
        }
      }
      this.populateGeneralSlcTrigger();
    });
  }

  getScriptPlace(event) {
    this.onSave = event.value == "onsave" ? true : false;
  }

  hubChange(event) {
    this.scripttype = event.target.value;
  }

  paramChange(event: Event) {
    this.isgeneralSlcTrigger = false;
    this.paramcolumns =
      event.target["options"][event.target["options"].selectedIndex].text;
    this.triggerCtrl =
      event.target["options"][event.target["options"].selectedIndex].value;
    this.triggerbindby = "";
    this.populateGeneralSlcTrigger();
  }

  populateGeneralSlcTrigger() {
    this.formControls.forEach((element) => {
      if (element.controlType == "dropdown") {
        if (this.paramcolumns == element.fieldName) {
          this.isgeneralSlcTrigger = true;
          element["choices"].forEach((choice) => {
            this.generalSlcTrigger.push({
              id: choice.id,
              value: choice["option"],
            });
          });
        }
      }
    });
  }

  bindTriggerChange(event: Event) {
    this.triggerbindby =
      event.target["options"][event.target["options"].selectedIndex].value;
  }

  saveform(rulename, formstate) {
    let validform = true;
    if (this.httprequest) {
      this.scripttype = "httprequest";
      tinymce.get("qf_appr_run_script").setContent("");
      this.apiurl = this.form.value.apiurl;
      this.apiheaders = this.form.value.apiheaders;
      this.apiparams = this.form.value.apiparams;
      this.apicolumns = this.form.value.apicolumns;
    }
    if (this.isgeneral) {
      this.scripttype = "general";
      this.apiurl = "";
      this.apiheaders = "";
      this.apiparams = "";
      this.apicolumns = "";
    }
    if (this.slchub) {
      this.apiurl = this.form.value.snapiurl;
      this.apiheaders = this.form.value.snapiheaders;
      this.apiparams = this.form.value.snapiparams;
      this.apicolumns = this.form.value.apicolumns;
    }

    if (this.form.value.workflowname == "") {
      this.toastr.error("Enter Script Name!", "Error!");
      validform = false;
    }
    if (this.scripttype === null || this.scripttype == "0") {
      this.toastr.error("Please Select Integration Hub!", "Error!");
      validform = false;
    }

    if (rulename == "runscript") {
      this.getColumnsData();

      if (this.beginid === 0 && formstate == "save") {
        this.data = {
          workflowid: this.form.value.workflowid,
          formid: this.form.value.formid,
          nodeid: this.form.value.nodeid,
          nodetype: this.nodetype,
          formname: this.form.value.workflowname,
          stage: this.form.value.workflowstage,
          scriptplace: this.form.value.scriptplace,
          runscript:
            this.scripttype == "general"
              ? tinymce.get("qf_appr_run_script").getContent({ format: "text" })
              : "",
          status: true,
          userid: this.api.getUserId(),
          scripttype: this.scripttype,
          apimethodtype:
            this.scripttype == "httprequest"
              ? this.isMethod
              : this.scripttype != "general"
              ? this.hubisMethod
              : "",
          apiurl: this.apiurl,
          apiheaders: this.apiheaders,
          apiparams: this.apiparams,
          apicolumns: this.apicolumns,
          mapcolumns: this.mapcolumns,
          apicolindex: this.form.value.apicolindex,
          paramcolumns: this.paramcolumns,
          triggerbindby: this.triggerbindby,
        };
      } else {
        this.data = {
          beginid: this.beginid,
          workflowid: this.form.value.workflowid,
          formid: this.form.value.formid,
          nodeid: this.form.value.nodeid,
          nodetype: this.nodetype,
          formname: this.form.value.workflowname,
          stage: this.form.value.workflowstage,
          scriptplace: this.form.value.scriptplace,
          runscript:
            this.scripttype == "general"
              ? tinymce.get("qf_appr_run_script").getContent({ format: "text" })
              : "",
          status: true,
          userid: this.api.getUserId(),
          scripttype: this.scripttype,
          apimethodtype:
            this.scripttype == "httprequest"
              ? this.isMethod
              : this.scripttype != "general"
              ? this.hubisMethod
              : "",
          apiurl: this.apiurl,
          apiheaders: this.apiheaders,
          apiparams: this.apiparams,
          apicolumns: this.apicolumns,
          mapcolumns: this.mapcolumns,
          apicolindex: this.form.value.apicolindex,
          paramcolumns: this.paramcolumns,
          triggerbindby: this.triggerbindby,
        };
      }
      if (validform) {
        if (this.beginid === 0) {
          //save
          this.api.saveRunScript(this.data).subscribe(
            (res: any) => {
              this.ngxService.stop();
              if (res && Number(res.responseCode) === 201) {
                this.toastr.success("Script saved successfully", "Success");
                this.dialogRef.close({ cancel: false, fetch: true });
              } else {
                this.toastr.error("Script failed! ", "Error!");
              }
              this.ngxService.stop();
            },
            (err) => {
              this.ngxService.stop();
              console.log(err);
            }
          );
        } else {
          this.api.updateRunScript(this.data, this.data.beginid).subscribe(
            (res: any) => {
              this.ngxService.stop();
              if (res && Number(res.responseCode) === 201) {
                this.toastr.success("Script Updated successfully", "Success");
                this.dialogRef.close({ cancel: false, fetch: true });
              } else {
                this.toastr.error("Script failed! ", "Error!");
              }
              this.ngxService.stop();
            },
            (err) => {
              this.ngxService.stop();
              console.log(err);
            }
          );
        }
      }
    }
    if (validform) {
      this.dialogRef.close(this.form.value);
    }
  }

  getColumnsData() {
    this.mapcolumns = [];

    var elmparam = document.getElementsByClassName("paramcolumns");
    var triggerbindbyparam = document.getElementsByClassName("bindtrigger");
    var slcelmparam = elmparam[0] as HTMLSelectElement;
    var triggerbindbyval = triggerbindbyparam[0] as HTMLSelectElement;
    this.paramcolumns = slcelmparam.options[slcelmparam.selectedIndex].text;
    this.triggerbindby =
      this.triggerCtrl == "dropdown"
        ? triggerbindbyval.options[triggerbindbyval.selectedIndex].value
        : "";

    var element = this.slchub
      ? document.getElementsByClassName("map-col-name")
      : document.getElementsByClassName("get-map-col-name");

    var elmdatatype = document.getElementsByClassName("slc-datatype");
    for (var i = 0; i < element.length; i++) {
      var slcelement = element[i] as HTMLSelectElement;
      var slcdatatype = elmdatatype[i] as HTMLSelectElement;
      let arrkey = this.arrcolumns[i];
      let arrval = slcelement.options[slcelement.selectedIndex].text;
      let datatype =
        this.onSave == true
          ? slcdatatype.options[slcdatatype.selectedIndex].value
          : "";
      this.mapcolumns.push([
        {
          apicol: arrkey,
          qfcol: arrval,
          datatype: datatype,
        },
      ]);
    }
  }

  toggleScript(rdmtdtype: MatRadioChange) {
    this.isMethod = "get";
    if (rdmtdtype.value == "general") {
      if (typeof tinymce !== "undefined") {
        tinymce.remove();
      }
      this.isgeneral = true;
      this.httprequest = false;
      this.slchub = false;
      this.isMethod = "";
      this.activateScript();
    }
    if (rdmtdtype.value == "httprequest") {
      this.slchub = false;
      this.isgeneral = false;
      this.httprequest = true;
    }
    if (rdmtdtype.value == "integration") {
      this.slchub = true;
      this.isgeneral = false;
      this.httprequest = false;
    }
  }

  toggleMethod(rdmethodtype: MatRadioChange) {
    this.isMethod = rdmethodtype.value;
  }
  sntoggleMethod(rdmethodtype: MatRadioChange) {
    this.hubisMethod = rdmethodtype.value;
  }

  validateapi() {
    let apiheader = this.form.value.apiheaders;
    if (apiheader == "") {
      apiheader = null;
    }
    this.api
      .apiGetResult(
        this.isMethod,
        this.form.value.apiurl,
        apiheader,
        this.form.value.apiparams
      )
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            const apidata = response.body;
            let apiindex = this.form.value.apicolindex;
            if (Array.isArray(apidata)) {
              this.getapiMethodData(apidata[0]);
            } else {
              const data = this.nestedProp(apidata, apiindex);
              if (Array.isArray(data)) {
                this.getapiMethodData(data[0]);
              } else {
                this.getapiMethodData(apidata);
              }
            }

            // this.getapiMethodData(data);
            this.toastr.success("API Validated Successfully", "Success");
          } else {
            this.toastr.error("API Not Validated", "Error");
          }
        },
        (err) => {
          this.toastr.error("There is some problem", "Error");
          console.log(err);
        }
      );
  }

  nestedProp(obj: object, path: string): unknown {
    return path
      .split(".")
      .reduce((o, k) => (o ? (o as any)[k] : undefined), obj);
  }

  getapiMethodData(data) {
    this.form.controls.apicolumns.setValue(Object.keys(data).toString());
  }

  validateSNapi() {
    this.api
      .apiGetResult(
        this.isMethod,
        this.form.value.snapiurl,
        this.form.value.snapiheaders,
        this.form.value.snapiparams
      )
      .subscribe(
        (response: any) => {
          if (response.status == 200) {
            this.toastr.success("API Validated Successfully", "Success");
          } else {
            this.toastr.error("API Not Validated", "Error");
          }
        },
        (err) => {
          this.toastr.error("There is some problem", "Error");
          console.log(err);
        }
      );
  }

  colMaping() {
    this.arrcolumns = [];
    if (this.form.value.apicolumns.length > 0) {
      let apicolumns = this.form.value.apicolumns;
      apicolumns = apicolumns.split(",");
      apicolumns.forEach((element) => {
        if (element.indexOf("qfexternal") === -1) {
          this.arrcolumns.push(element);
        }
      });
    }
  }

  close() {
    if (this.beginid == 0 && this.form.dirty) {
      this.closedialog = false;
      this.confirmFormDeleteDialogRef = this.dialog.open(UnsavedComponent, {
        width: "400px",
        id: "unsavedchanges",
        disableClose: true,
        data: {
          header: "Unsaved Changes?",
          content:
            "You have unsaved Changes on this workflow stage. Do you want to continue?",
          postivebtnLabel: "Ok",
          negativeBtnLabel: "Cancel",
        },
      });

      this.confirmFormDeleteDialogRef.afterClosed().subscribe(
        (result) => {
          if (result && !result.cancel) {
            this.dialogRef.close();
          }
        },
        (error) => {
          console.error("Error while closing the dialog ", error);
        }
      );
    }
    if (this.closedialog) {
      this.dialogRef.close();
    }
  }

  ngAfterViewInit(): void {
    if (typeof tinymce !== "undefined") {
      tinymce.remove();
    }
    this.activateScript();
  }

  activateScript() {
    tinymce.init({
      selector: "textarea#qf_appr_run_script",
      force_br_newlines: true,
      force_p_newlines: false,
      gecko_spellcheck: true,
      forced_root_block: "",
      height: 200,
      statusbar: false,
      menubar: false,

      plugins: "nonbreaking",
      nonbreaking_force_tab: true,

      content_style:
        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    });
  }
}
